// src/components/Footer.jsx
import React from "react";
import { Heart, Mail, Phone } from "lucide-react";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-white border-t mt-auto">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-8">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {/* Company Info */}
            <div className="col-span-1">
              <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase mb-4">
                iPENH Healthcare
              </h3>
              <p className="text-gray-600 text-sm">
                Providing quality healthcare solutions for a better tomorrow.
              </p>
              <div className="mt-4 flex space-x-6">
                <a
                  href="tel:+254202163762"
                  className="text-gray-500 hover:text-gray-900">
                  <Phone className="h-5 w-5" />
                </a>
                <a
                  href="mailto:info@pensoft.co.ke"
                  className="text-gray-500 hover:text-gray-900">
                  <Mail className="h-5 w-5" />
                </a>
              </div>
            </div>

            {/* Quick Links */}
            <div>
              <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase mb-4">
                Quick Links
              </h3>
              <ul className="space-y-2">
                <li>
                  <Link
                    to="/staged-medical-claim"
                    className="text-gray-600 hover:text-gray-900 text-sm">
                    Medical Claims
                  </Link>
                </li>
                <li>
                  <Link
                    to="/staged-medical-claim"
                    className="text-gray-600 hover:text-gray-900 text-sm">
                    Paid Claims
                  </Link>
                </li>
                <li>
                  <Link
                    to="/staged-medical-claim"
                    className="text-gray-600 hover:text-gray-900 text-sm">
                    Pending Claims
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="/staged-medical-claim"
                    className="text-gray-600 hover:text-gray-900 text-sm">
                    Reports
                  </Link>
                </li> */}
              </ul>
            </div>

            {/* Support */}
            <div>
              <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase mb-4">
                Support
              </h3>
              <ul className="space-y-2">
                <li>
                  <Link
                    to="/staged-medical-claim"
                    className="text-gray-600 hover:text-gray-900 text-sm">
                    Help Center
                  </Link>
                </li>
                <li>
                  <Link
                    to="/staged-medical-claim"
                    className="text-gray-600 hover:text-gray-900 text-sm">
                    FAQs
                  </Link>
                </li>
                <li>
                  <Link
                    to="/staged-medical-claim"
                    className="text-gray-600 hover:text-gray-900 text-sm">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>

            {/* Legal */}
            <div>
              <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase mb-4">
                Legal
              </h3>
              <ul className="space-y-2">
                <li>
                  <Link
                    to="/staged-medical-claim"
                    className="text-gray-600 hover:text-gray-900 text-sm">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/staged-medical-claim"
                    className="text-gray-600 hover:text-gray-900 text-sm">
                    Terms of Service
                  </Link>
                </li>
                <li>
                  <Link
                    to="/staged-medical-claim"
                    className="text-gray-600 hover:text-gray-900 text-sm">
                    Compliance
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Bottom Bar */}
          <div className="mt-8 pt-8 border-t border-gray-200">
            <div className="flex items-center justify-between">
              <p className="text-sm text-gray-500">
                © {currentYear} iPENH Healthcare. All rights reserved.
              </p>
              <div className="flex items-center text-sm text-gray-500">
                {/* <span>Made </span> */}
                {/* <Heart className="h-4 w-4 mx-1 text-red-500" /> */}
                <span>By iPENH Team</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
