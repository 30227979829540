import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Layout from "./components/Layout";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import StagedMedicalClaim from "./pages/StagedMedicalClaim";
import ProtectedRoute from "./components/ProtectedRoute";
import PaidClaims from "./pages/PaidClaims";
import PendingClaims from "./pages/PendingClaims";

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Public routes */}
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />

        {/* Redirect root to dashboard if authenticated, otherwise to register */}
        <Route
          path="/"
          element={
            localStorage.getItem("token") ? (
              <Navigate to="/dashboard" replace />
            ) : (
              <Navigate to="/register" replace />
            )
          }
        />

        {/* Protected routes */}
        <Route element={<Layout />}>
          <Route
            path="/dashboard"
            element={
              // <ProtectedRoute>
              <Dashboard />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/staged-medical-claim"
            element={
              // <ProtectedRoute>
              <StagedMedicalClaim />

              // </ProtectedRoute>
            }
          />
          <Route
            path="/paid-claims"
            element={
              // <ProtectedRoute>
              <PaidClaims />

              // </ProtectedRoute>
            }
          />
          <Route
            path="/pending-claims"
            element={
              // <ProtectedRoute>
              <PendingClaims />

              // </ProtectedRoute>
            }
          />
        </Route>

        {/* Catch all route - redirect to dashboard if authenticated, otherwise to register */}
        <Route
          path="*"
          element={
            localStorage.getItem("token") ? (
              <Navigate to="/dashboard" replace />
            ) : (
              <Navigate to="/register" replace />
            )
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
