// src/components/Sidebar.jsx
import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Home,
  FileText,
  Settings,
  Users,
  Activity,
  X,
  CheckCircle2,
  Clock,
} from "lucide-react";

const Sidebar = ({ isOpen, setIsOpen }) => {
  const location = useLocation();

  const navigation = [
    {
      name: "Medical Claims",
      href: "/staged-medical-claim",
      icon: FileText,
    },
    { name: "Paid Claims", href: "/paid-claims", icon: CheckCircle2 },
    { name: "Pending Claims", href: "/pending-claims", icon: Clock },
    // { name: "Reports", href: "/reports", icon: Activity },
    // { name: "Settings", href: "/settings", icon: Settings },
  ];

  const NavItem = ({ item }) => {
    const isActive = location.pathname === item.href;
    return (
      <Link
        to={item.href}
        className={`flex items-center px-4 py-2 text-sm font-medium rounded-md ${
          isActive
            ? "bg-blue-100 text-blue-600"
            : "text-gray-600 hover:bg-gray-50"
        }`}>
        <item.icon className="mr-3 h-5 w-5" />
        {item.name}
      </Link>
    );
  };

  return (
    <>
      {/* Mobile sidebar */}
      <div
        className={`fixed inset-0 z-40 md:hidden ${
          isOpen ? "block" : "hidden"
        }`}>
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-75"
          onClick={() => setIsOpen(false)}
        />
        <div className="fixed inset-y-0 left-0 flex flex-col max-w-xs w-full bg-white">
          <div className="flex justify-end p-4">
            <button onClick={() => setIsOpen(false)}>
              <X className="h-6 w-6 text-gray-400" />
            </button>
          </div>
          <div className="flex-1 px-2 space-y-1">
            {navigation.map((item) => (
              <NavItem key={item.name} item={item} />
            ))}
          </div>
        </div>
      </div>
      {/* Desktop sidebar */}
      {/* // src/components/Sidebar.jsx */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="fixed flex flex-col w-64 border-r border-gray-200 bg-white">
          <div className="flex-1 flex flex-col pt-20 pb-4 overflow-y-auto">
            <nav className="flex-1 px-2 space-y-1">
              {navigation.map((item) => (
                <NavItem key={item.name} item={item} />
              ))}
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
