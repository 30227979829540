// src/components/Header.jsx
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { LogOut, Menu, Bell, User } from "lucide-react";
import IpenhLogo from "../images/ipenhLogo.png";
const Header = ({ sidebarOpen, setSidebarOpen }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear local storage/session
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <header className="fixed w-full top-0 z-30 bg-white shadow">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <button
              onClick={() => setSidebarOpen(!sidebarOpen)}
              className="md:hidden p-2 rounded-md text-gray-400 hover:text-gray-500">
              <Menu className="h-6 w-6" />
            </button>
            <div className="flex-shrink-0 flex items-center ml-4 md:ml-0">
              <Link to="/">
                <img
                  className="h-14 w-auto"
                  src={IpenhLogo}
                  alt="iPENH Healthcare Logo"
                />
              </Link>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            {/* <button className="p-2 rounded-md text-gray-400 hover:text-gray-500">
              <Bell className="h-6 w-6" />
            </button> */}
            <div className="relative">
              <button className="p-2 rounded-md text-gray-400 hover:text-gray-500">
                <User className="h-6 w-6" />
              </button>
            </div>
            <button
              onClick={handleLogout}
              className="p-2 rounded-md text-gray-400 hover:text-gray-500">
              <LogOut className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
