// src/pages/Dashboard.jsx
import React from "react";
import { Card } from "antd";
import { Activity, FileText, DollarSign, Users } from "lucide-react";

const Dashboard = () => {
  const stats = [
    {
      name: "Total Claims",
      value: "2,651",
      icon: FileText,
      change: "+12%",
      changeType: "increase",
    },
    {
      name: "Pending Claims",
      value: "145",
      icon: Activity,
      change: "-3%",
      changeType: "decrease",
    },
    {
      name: "Total Amount",
      value: "$124,592",
      icon: DollarSign,
      change: "+8%",
      changeType: "increase",
    },
    {
      name: "Active Members",
      value: "1,280",
      icon: Users,
      change: "+5%",
      changeType: "increase",
    },
  ];

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
        {stats.map((stat) => (
          <Card key={stat.name} className="hover:shadow-lg transition-shadow">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <stat.icon className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-4">
                <p className="text-sm font-medium text-gray-500">{stat.name}</p>
                <div className="flex items-baseline">
                  <p className="text-2xl font-semibold text-gray-900">
                    {stat.value}
                  </p>
                  <span
                    className={`ml-2 text-sm font-medium ${
                      stat.changeType === "increase"
                        ? "text-green-600"
                        : "text-red-600"
                    }`}>
                    {stat.change}
                  </span>
                </div>
              </div>
            </div>
          </Card>
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <Card title="Recent Claims">
          {/* Add recent claims table/list here */}
        </Card>
        <Card title="Activity">{/* Add activity feed here */}</Card>
      </div>
    </div>
  );
};

export default Dashboard;
