// src/pages/PaidClaims.jsx
import React from "react";

const PaidClaims = () => (
  <div className="flex items-center justify-center h-screen text-gray-700">
    <h1 className="text-2xl font-semibold">Paid Claims - Coming Soon!</h1>
  </div>
);

export default PaidClaims;
